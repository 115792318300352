.content-post{
	.title{
		@include headings{
			font-size: 1.75rem;
			margin-bottom: rem-calc(20);
		}
	}
	.content{
		text-align: justify;
		img{
			max-width: 100%;
			display: block;
			height: auto;
			margin: 0 auto;
		}
	}

	ul.tags{
		list-style: none;
		padding: 0px;
		margin: 0px;
		display: inline-block;
		width: 100%;
		border-top: 1px solid #ddd;
		padding-top: 30px;
		padding-bottom: 15px;

		a{
			color: #fff;
			background-color: #f00;
			padding: 3px 10px;
			display: inline-block;

			&:hover{
				columns: #fff;
				background-color: #000;
			}
		}
	}

	.other-news{
		.post{
			.post-img{
				img{
					width: 100%;
					height: 150px;
				}
			}
			.post-cap{
				@include headings{
					line-height: 1;
					a{
						font-size: 1rem;
					}
				}
			}
		}
	}
}