/****Menu danh mục fixed*/
.cate-menu{
    width: 100%;
    z-index: 8888;
    padding: 0px;
    margin: 0px;
    list-style: none;
    display: inline-block;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
    li{
    	width: 100%;
    	float: left;
    	// position: relative;
    	a{
    		color: $color-1st;
		    font-weight: 700;
		    padding-left: 15px;
		    font-size: rem-calc(16);
		    width: 100%;
		    padding: 10px 15px;
		    padding-right: 20px;
		    display: inline-block;
		    position: relative;
		    text-transform: capitalize;
		    position: relative;
		    border-bottom: 1px solid #ddd;
		    &:hover{
		    	color: $color-2st;
		    	text-decoration: none;
		    	background-color: #fff;
		    }
		    i{
		    	position: absolute;
			    right: 15px;
			    font-size: rem-calc(18);
		    }
    	}
    	&:hover .cate-menu-sub1{
    		opacity: 1;
    		visibility: visible;
    	}
    	.cate-menu-sub1{
    		width: 220px;
    		height: 100%;
		    position: absolute;
		    top: 0px;
		    left: 100%;
		    background: #fff;
		    margin-left: 0;
		    z-index: 8888;
		    border: 1px solid #ddd;
		    box-shadow: -2px 0 8px 0 rgba(0, 0, 0, 0.06);
		    visibility: hidden;
		    opacity: 0;
		    list-style: none;
		    padding: 0px;
		    li{
		    	width: 100%;
		    	a{
		    		color: $color-1st;
		    		font-size:rem-calc(16);
					&:hover{
						background-color: #f5f5f5;
						color: $color-2st;
					}
		    	}
		    	.cate-menu-sub2{
		    		width: 100%;
		    		position: relative;
		    		margin: 0px;
		    		padding: 0px;
		    		list-style: none;
		    		li{
		    			width: 100%;
		    			list-style: none;
		    			a{
		    				color: $color-1st;
		    				border: none;
		    				font-weight: $font-regular;
		    				font-size: rem-calc(14);
		    				padding: 5px 15px;
		    				&:hover{
		    					background-color: #f5f5f5;
		    					color: $color-2st;
		    				}
		    			}
		    		}
		    	}
		    }
    	}
    	&:last-child{
    		a{
    			border-bottom: none;
    		}
    	}
    }
}


/*main menu*/
ul.main-menu{
	padding: 0px;
	margin: 0px;
	list-style: none;
	li{
		position: relative;
		float: left;
		a{
			color: #fff;
			font-size: rem-calc(15);
			text-transform: uppercase;
			display: inline-block;
			padding: 0px 15px;
			line-height: 40px;
			&:hover{
				background-color: $color-2st;
			}
		}
		&:hover .main-menu-sub1{
			opacity: 1;
			visibility: visible;
		}
		.main-menu-sub1{
			position: absolute;
			left: 0px;
			top: 100%;
			background-color: #fff;
			width: 220px;
			z-index: 999;
			display: block;
			opacity: 0;
			visibility: hidden;
			box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
			li{
				width: 100%;
				display: inline-block;
				position: relative;
				a{
					color: $color-1st;
					padding: 10px 15px;
					line-height: 18px;
					display: inline-block;
					width: 100%;
					font-weight: $font-regular;
					font-size: rem-calc(14);
					text-transform: none;
					border-bottom: 1px solid rgba(0,0,0,.1);
					background-color: #fff;
					&:hover{
						background-color: transparent;
						color: $color-2st;
					}
				}
				// .main-menu-sub2{
				// 	position: absolute;
				// 	left: 100%;
				// 	top: 0px;
				// 	background-color: #fff;
				// 	width: 230px;
				// 	z-index: 999;
				// 	display: block;
				// 	opacity: 0;
				// 	visibility: hidden;
				// }
				// &:hover .main-menu-sub2{
				// 	opacity: 1;
				// 	visibility: visible;
				// }
				// &.active{
				// 	a{
				// 		background-color: transparent;
				// 	}
				// }
			}
		}
		&.active{
			a{
				background-color: $color-2st;
			}
		}
	}

}

@media (max-width: 991px){
	#menu{
	    position: fixed;
	    padding: 0;
	    -ms-transform: translateX(280px);
	    -webkit-transform: translateX(280px);
	    transform: translateX(280px);
	    background-color: #fff;
	    min-height: 100%;
	    height: 100vh;
	    z-index: 99999;
	    width: 280px;
	    right:0;
	    top: 0;
	    transition: all 0.3s ease;
	    opacity: 1;
	    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.2) inset;

	}
	#menu ul {
	  	list-style: none;
	  	margin-top: 0;
	  	padding: 0;
	  	li{
	  		width: 100%;
		  	a{
		  		color:inherit;
			    display: inline-block;
			    width: 100%;
			  	padding: 8px 0 8px 25px;
			  	text-decoration: none;
			  	transition: all 0.3s ease;
			  	line-height: 1.8;
		  	    border-top: 1px solid rgba(255, 255, 255, 0.1);
    			border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    			font-weight: bold;
			    i{
				  	margin-top: 3px;
				  	width: 30px;
				  	position: relative;
					top: 2px;
					color:#757575;
			    }
			    &.active+ul{
					display: block;
			    }
		  	}
		  	ul{
		  		margin-top: 0;
  				display: none;
  				li{
  					border-bottom: none;
  					a{
  						padding-left: 35px;
  						font-weight: normal;
  						&:hover{
  							background-color: #fff;
  						}
  					}
  				}
		  	}
	  	}
	  	a{
	  		span[class*='fa-caret'] { float: right; }
	  		&.active,
	  		&:hover{
				background: rgba(0, 0, 0, 0.04);
	  		}
	  	}
	}
	.support-menu{
	    display: block;
	    padding: 0px 15px;
	    font-size: 14px;
	    display: inline-block;
	    width: 100%;
	    padding-top: 15px;
	    margin-top: 5px;
	    li{
	    	color: #333;
	    	display: inline-block;
	    	width: 100%;
	    }
	}
	.logo-menu-mobile{
		background-color: #fff;
		padding: 5px 15px;
		border-bottom: 1px solid #eee;
		img{
			width: 50%;
		}
	}
	@media (max-width: 767px){
		.button-res{
			.showmenu{
				display: block;
				padding: 5px 15px;
				position: fixed;
				z-index: 555;
				left: 0;
				right: 0;
				top:0;
				background-color: #fff;
	    		border-bottom: 1px solid rgba(0, 0, 0, 0.1);

	    		display: flex;
	    		justify-content: space-between;
	    		align-items: center;

	    		.short-menu-btn{
	    			background-color: $color-2st;
	    			width: 40px;
	    			height: 40px;
	    			border-radius: 100%;
	    			text-align: center;
	    			i{
	    				line-height: 40px;
	    				color: #fff;
	    				font-size: rem-calc(20);
	    			}
	    		}

	    		.cart-mb{
	    			width: 40px;
	    			height: 40px;
	    			border-radius: 100%;
	    			text-align: center;
	    			background-color: $color-3st;
	    			i{
	    				color: #fff;
	    				line-height: 40px;
	    			}
	    		}
			}
		}
		.menu-prduct{
			b{
				display: none;
			}
			.cate-menu{
				display: none;
			}
		}
		@media(min-width: 576px){

		}

	}
	@media (min-width: 768px){
		.header-main{
			padding: rem-calc(20) 0;
		}
		.button-res{
			position: absolute;
			z-index: 5555;
			right: 40px;
			top:70px;
			.showmenu{
				display: block;
				padding: 18px 20px;
				text-align: right;
	    		border-bottom: 0px;
			}
		}
	}
}

@media (min-width: 768px){

}

@media (min-width: 992px){
	.header-main{
		background-color: $color-3st;
		// .menu-prduct{
		// 	width: 25%;
		// 	position: relative;
		// 	b{
		// 		background-color: $color-3st;
		// 		color: #fff;
		// 		display: inline-block;
		// 		width: 100%;
		// 		line-height: 50px;
		// 		text-transform: uppercase;
		// 		padding: 0px 15px;
		// 		padding-right: 0px;
		// 		font-family: 'Roboto Condensed', sans-serif;
		// 		font-weight: 700;
		// 		font-size: rem-calc(18);
		// 		i{
		// 			font-size: 18px;
		// 			padding-right: 10px;
		// 		}
		// 	}
		// 	.cate-menu{
		// 		display: none;
		// 	}
		// }

		// #menu{
		// 	width: 75%;
		// 	ul{
		// 		padding: 0px;
		// 		margin: 0px;
		// 		list-style: none;
		// 	}
		// }
	}
	// .fixed-nav{
	// 	position: fixed;
	//     left: 0px;
	//     right: 0px;
	//     z-index: 9999;
	//     top: 0px;
	    .cate-menu-top{
			top: 50px;
		    position: absolute;
		    width: 100%;
		    z-index: 8888;
		    max-height: inherit;
		    overflow-y: inherit;
		    opacity: 0;
		    background: #f4f4f4;
	    }
	    .menu-prduct{
	    	&:hover{
	    		.cate-menu{
		    		display: block;
		    		opacity: 1;
		    	}
	    	}
	    }
	// }
	.support-menu{
		display: none;
	}
}

@media (min-width: 1200px){
	
}