// .pro-detail{}
.wrap-pro-detail{
	width: 100%;
	background-color: #fff;
	padding: 15px;
	.pro-detail-img{
		width: 30%;
		.img{
			border: 1px solid rgba(0,0,0,.1);
			padding: 30px;
			display: flex;
			justify-content: center;
			height: 300px;
			max-height: 300px;
			img{
				max-width: 100%;
				max-height: 100%;
				display: block;
			}
		}
	}
	.pro-detail-other{
		width: 24%;
		display: inline-block;
		min-height: 100%;
	}
	.pro-detail_content{
		padding: 0px 30px;
		width: 46%;
		.pro-props__name{
			font-size: 22px;
			font-weight: $font-medium;
			margin: 0px;
			padding: 0px;
		}
		.pro-props{
			padding-top: 5px;
			.pro-props__price{
			    font-size: 22px;
			    color: $color-3st;
			    display: inline-block;
			}
			.pro-props__oldprice{
				margin-left: 15px;
			    text-decoration: line-through;
			    color: gray;
			    font-size: 16px;
			    display: inline-block;
			}
		}
		.pro-desc{
			display: inline-block;
			padding: 10px 15px;
			width: 100%;
			border: 1px solid $color-3st;
			margin-top: 5px;
			font-size: rem-calc(14);
			position: relative;
			ul{
				padding: 0px;
				padding-left: 15px;
				margin: 0px;
				li{}
			}
			&:before{
				border-right: 0 solid $color-3st;
			    border-left: 60px solid transparent;
			    border-bottom: 60px solid $color-3st;
			    content: "";
			    height: 0;
			    position: absolute;
			    right: -0px;
			    bottom: 0;
			    width: 0;
			}
			&:after{
			    content: "\f00c";
			    font-family: fontAwesome;
			    color: #fff;
			    position: absolute;
			    right: 10px;
			    bottom: 5px;
			    font-size: rem-calc(18);
			}
		}
		// .tskt{
		// 	padding: 0px;
		// 	margin: 0px;
		// 	list-style: none;
		// 	width: 100%;
		// 	display: inline-block;
		// 	li{
		// 		display: inline-block;
		// 		width: 100%;
		// 		position: relative;
		// 		font-size: rem-calc(14);
		// 		font-family: 'Roboto', sans-serif;
		// 		padding: 5px 0px;
		// 		border-bottom: 1px solid #eee;
		// 		padding-left: 20px;
		// 		&:before{
		// 			content: "\f00c";
		// 			position: absolute;
		// 			left: 0px;
		// 			font-family: fontAwesome;
		// 			font-size: rem-calc(14);
		// 			color: #4caf50;
		// 		}
		// 		&:last-child{
		// 			border-bottom: 0px;
		// 		}
		// 	}
		// }
		.masanpham{
			padding:0px;
			margin:0px;
			list-style: none;
			li{
				font-size: 13px;
			}
		}
		.pro-property{
			margin-top: 30px;
			.property-item{
				width: 50px;
				margin-right: 35px;
				.qty {
				    display: inline-block;
				    position: relative;
				    #quantity-detail {
					    line-height: 39px;
					    margin: 0;
					    display: inline-block;
					    width: 100px;
					    padding-right: 0;
					    height: 38px;
					    width: 40px;
					    margin-left: 20px;
					    border: 1px solid rgba(0,0,0,1);
					}
				    .qtyminus {
				    	position: absolute;
				    	text-align: center;
					    right: 40px;
					    top: 0px;
					    font-size: 12px;
					    width: 20px;
					    height: 38px;
					    cursor: pointer;
					    display: block;
					    line-height: 38px;
					}
					.qtyplus {
						position: absolute;
						text-align: center;
					    right: -20px;
					    top: 0px;
					    font-size: 12px;
					    width: 20px;
					    height: 38px;
					    cursor: pointer;
					    display: block;
					    line-height: 38px;
					}
				}
			}
			// .buy-now{
				.btn-muangay{
					background-color: $color-2st;
					text-transform: uppercase;
					color: #fff;
					border: none;
					display: inline-block;
					padding: 6px 30px;
					font-weight: $font-bold;
					margin-left: 5px;
					border-radius: 2px;
					font-size: rem-calc(16);
					&:hover{
						background-color: $color-3st;
						color: #fff;
						cursor: pointer;
					}
				}
			// }
		}
	}

	@media(max-width: 767px){
		.pro-detail-img{
			width: 100%;
		}
		.pro-detail_content{
			width: 100%;
			margin-left: 0px;
			border: none;
			padding: 0px;
			padding-top: 15px;
		}
		.pro-detail-other{
			width: 100%;
			border: none;
			padding: 15px 10px;
			border: 1px solid #ddd;
			border-radius: 10px;
			margin: 15px 0px;
		}
	}
}

// .pro-gioithieu,
// .pro-tskt,
// .pro-commnet{
// 	padding-top: 30px;
// }
.title-pro-meta{
	display: inline-block;
	font-size: 18px;
	font-weight: $font-bold;
	color: $color-1st;
	margin-bottom: 15px;
}

// @media(max-width: 767px){
// 	@media(max-width: 576px){
// 		.wrap-pro-detail{
// 			.pro-detail_content{
// 				.pro-property{
// 					.buy-now{
// 						.btn-muangay{
// 							// margin-top: 10px;
// 							// width: 50%;
// 							// width: calc(50% - 2.5px);
// 							margin-right: 5px;
// 							display: block;
// 							margin-left: 0px;
// 							text-align: center;
// 							float: left;
// 							&:last-child{
// 								margin-right: 0px;
// 							}
// 						}
// 					}
// 				}
// 			}
// 		} 
// 	}
// }