.title-main {
	text-align: left;
	margin-bottom: 0px;
	@include headings{
		font-weight: $font-bold;
		font-size: rem-calc(18);
		display: inline-block;
		color: #fff;
		margin: 0px;
		background-color: $color-2st;
		position: relative;
		line-height: 40px;
		padding-right: 15px;
		a{
			color: #fff;
		}
		&:after{
			border-left: 0 solid $color-2st;
		    border-right: 20px solid transparent;
		    border-top: 40px solid $color-2st;
		    content: "";
		    height: 0;
		    position: absolute;
		    right: -20px;
		    top: 0;
		    width: 0;	
		}
		&:before{
		    content: "";
		    background-color: $color-2st;
		    height: 40px;
		    position: absolute;
		    left: -10px;
		    top: 0;
		    width: 10px;	
		    border-top-left-radius: 5px;
		}
	}
}
.list-service{
	ul{
		padding: 0px 15px;
		padding-right: 0px;
		margin: 0px;
		list-style: none;
		li{
			float: left;
			a{
				color: $color-2st;
				line-height: 40px;
				font-size: rem-calc(15);
				font-weight: $font-regular;
				&:hover{
					color: $color-1st;
					text-decoration: none;
				}
			}
		}
	}
}
.title-sidebar{
	margin-bottom: 10px;
	font-size: rem-calc(16);
	font-weight: $font-medium;
	display: inline-block;
	width: 100%;
	border-bottom: 1px solid rgba(0,0,0,.1);
	padding-bottom: 5px;
}
.tskt{
	background-color: #f4f4f4;
	padding: 15px;
	margin: 0px;
	list-style: none;
	li{
		padding: 0px;
		font-size: rem-calc(14);
		position: relative;
		padding-left: 15px;
		&:before{
			content: "";
			position: absolute;
			left: 0px;
			top: 6px;
			background-color: #888;
			border-radius: 100%;
			width: 5px;
			height: 5px;
		}
		// &:nth-child(2n +2){
		// 	background-color: #fff;
		// }
	}
}
.title-other {
	text-align: left;
	margin-bottom: 15px;
	margin-top: 30px;
	border-bottom: 1px solid #ddd;
	.tit{
		font-weight: bold;
		font-size: rem-calc(18);
		display: inline-block;
		line-height: 45px;
		position: relative;
		margin: 0px;
		color: $color-1st;
		display: inline-block;
		width: 100%;
	}
	
}

.sidebar-box{
	background-color: #fff;
	padding: 15px;
	border-radius: 5px;
	margin-top: 10px;
	display: inline-block;
	width: 100%;
	.form-filter{
		list-style: none;
		padding: 0px;
		margin: 0px;
		li{
			display: inline-block;
			width: 100%;
			font-size: rem-calc(14);
			a{
				display: inline-block;
				width: 100%;
				padding: 10px 0px;
				color: $color-2st;
				&:hover{
					color: $color-3st;
				}
			}
			label{
				display: inline-block;
				align-items: center;
				text-transform: capitalize;
			}
			input{
				margin-right: 10px;
			}
		}
	}
}

.title-post{
	color: $color-1st;
	font-size: rem-calc(32);
	display: inline-block;
	width: 100%;
}

// @media(max-width: 767px){
// 	.title-main {
// 		@include headings{
// 			font-size: rem-calc(16);
// 		}
// 	}
// 	// .list-service{
// 	// 	ul{
// 	// 		li{
// 	// 			display: none;
// 	// 			&:last-child{
// 	// 				display: block;
// 	// 			}
// 	// 		}
// 	// 	}
// 	// }
// 	@media(min-width: 576px){
// 	}
// }