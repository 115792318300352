.next-page{
	display: inline-table;
    width: 100%;
    background-color: transparent;
    padding: 0px;
    margin: 0px;
    padding-top: 15px;
}
.next-page li{
	color: $color-1st;
	background-color:#FFF;
	border:1px solid rgba(0,0,0,.2);
	width: 30px;
	height: 30px;
	line-height: 30px;
	display: inline-table;
	text-align: center;
	float: left;
	margin-right: 5px;
	border-radius: 2px;
	&.pagnSep{
		display: none;
	}
	&.pagnLead{
		display: none;
	}
	&.pagnCur{
		color:#fff;
		background-color:$color-2st;
	}
}
.next-page li a{
	color: $color-1st;
	display: inline-table;
	width: 100%;
	height: 30px;
}
.next-page li a:hover{
	color:#fff;
	background-color:$color-2st;
}