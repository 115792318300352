// #support {
//   width: 360px;
//   position: fixed;
//   bottom: -172px;
//   left: 0px;
//   overflow: hidden;
//   z-index: 99999;
//   box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
//   font-family: 'Roboto Condensed', sans-serif;
//   background-color: #f4f4f4;
//   padding-bottom: 10px;
//   border-top-right-radius: 10px;
// }
// #support h3 {
//   width: 100%;
//   height: 40px;
//   background-color: $color-3st;
//   color: #ffeb3b;
//   font-size: rem-calc(22);
//   font-weight: $font-bold;
//   text-align: center;
//   line-height: 40px;
//   text-transform: uppercase;
//   border-top-right-radius: 10px;
// }
// #support h3:hover {
//   cursor: pointer;
// }

.support{
  padding: 0px;
  margin: 0px;
  list-style: none;
  width: 33.3%;
  text-align: center;
  font-size: rem-calc(12);
  border-right: 1px dotted rgba(0,0,0,.2);
  li{
    display: inline-block;
    &.hotline{
      color: $color-3st;
      display: inline-block;
      width: 100%;
      font-size: rem-calc(14);
      font-weight: $font-medium;
    }
    a{
      display: inline-block;
      margin-left: 5px;
    }
  }
  &:last-child{
    border-right: 0px;
  }
}

  
