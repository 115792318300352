.header-mid{
	background-color: #fff;
	padding: 10px 0px;
	.logo{
		img{
			max-width: 100%;
		}
	}
	&--list{
		padding: 0px;
		li{
			display: inline-block;
			a{
				padding: 0px 0px;
				padding-right: 45px;
				font-weight: $font-regular;
				text-align: center;
				display: inline-block;
				line-height: 35px;
				color: $color-1st;
				position: relative;
				text-transform: uppercase;
				&:hover{
					color: $color-2st;
				}
				&:before{
					content: "";
					position: absolute;
					right: 0px;
					width: 35px;
					height: 35px;
					background-color: transparent;
					border: 1px solid $color-2st;
					border-radius: 35px;
					
					background-image: url('../img/i-cart.png');
					background-repeat: no-repeat;
					background-position: center center;
				}
			}
		}
	}
}

.search{
	position: relative;
    border-radius: 0px;
    width: 100%;
    background: #fff;
    height: 35px;
    display: inline-block;
    border-radius: 3px;
    font-family: 'Roboto', sans-serif;
    font-size: rem-calc(14);
    // border: 1px solid rgba(0,0,0,.2);
    border: 1px solid $color-2st;
    input{
	    width: calc(100% - 40px);
	    background: transparent;
	    margin: 0;
	    padding: 0px 15px;
	    line-height: 35px;
	    height: 35px;
	    color: #333;
	    border: 0 none;
	    outline: 0px;
	    font-size: rem-calc(14);
	}
	.search-button {
	    position: absolute;
	    background-color: transparent;
	    top: 0;
	    right: -1px;
	    height: 35px;
	    padding: 0;
	    width: 40px;
	    font-size: 14px;
	    color: $color-2st;
	    margin: 0;
	    outline: none;
	    border: 0 none;
	    border-top-right-radius: 3px;
	    border-bottom-right-radius: 3px;
	    &:hover{
	    	cursor: pointer;
	    }
	    i{
		    transform: rotate(0deg) translate(0px, 0px);
		    transition: all 0.4s ease;
		    color: $color-2st;
		    font-size: 18px;
		}
	}
}
// @media (max-width: 767px){
// 	.logo-mobile{
// 		float: left;
// 		img{
// 			max-width: 100%;
// 		}
// 	}
// 	.header-mid{
// 		padding-top: 60px;
// 		padding-bottom: 0px;
// 		.logo{
// 			display: none;
// 		}
// 		&--list{
// 			position: absolute;
// 			right: 70px;
// 			top: 7px;
// 			a{
// 				width: 40px !important;
// 				height: 40px !important;
// 				border-radius: 100% !important;
// 				background-color: $color-2st !important;
// 				color: #fff !important;
// 				line-height: 40px !important;
// 				padding: 0px !important;
// 			}
// 		}
// 	}
// 	.search{
// 		width: calc(100% - 0px);
// 		margin-bottom: 0px;
// 		margin-left: 0px;
// 	}
// 	@media (min-width: 576px){
// 	}
// }

// @media (min-width: 768px){
// 	@media (max-width: 991px){
// 	}
// }
// @media (min-width: 992px){
// }
// @media (min-width: 1200px){
// }