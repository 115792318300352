footer{
	margin-top: 15px;
	background-color: #fff;
	font-size: rem-calc(15);
}
.top-footer{
	padding: 30px 0px;
	.tit-footer{
		font-size: rem-calc(18);
		display: inline-block;
		width: 100%;
		margin-bottom: 10px;
		font-weight: $font-medium;
	}
	.list-footer{
		list-style: none;
		margin: 0;
		padding: 0;
		li{
			a{
				display: block;
				text-decoration: none;
				font-size: rem-calc(15);
				color: $color-1st;
				position: relative;
				padding: 3px 0px;
				display: inline-block;
				padding-left: 15px;
				&:before{
					content: "\f105";
					font-family: fontAwesome;
					color: $color-1st;
					position: absolute;
					left: 0px;
					top: 3px;
				}
				&:hover{
					color: $color-2st;
				}
			}
		}
	}
}
@media (max-width: 767px){
	.top-footer{
		.tit-footer{
			margin-top: 15px;
		}
	}
}
