.sidebox{
	.post{
		display: flex;
		.post-img{
			width: 40%;
			padding-right: 15px;
			img{
				display: block;
				width: 100%;
				height: auto;
				transform: initial;
    			opacity: initial;
				&:hover{

				}
			}
		}
		.post-cap{
			width: 60%;
			@include headings{
				font-size: 14px;
				line-height: 1.4;
				margin-bottom: 3px;
				a{
					font-size: 14px;
					line-height: 1;	
					font-weight: 500;
				}
			}
			p{
				font-size: 12px;
			    color: #222;
			}
		}
	}
}

@media (max-width: 767px){
	.sidebox{
		.post{
			margin-bottom: rem-calc(15);
		}
	}
	@media (min-width: 576px){

	}
}

@media (min-width: 768px){

}

@media (min-width: 992px){
	.sidebox{
		.post{
			margin-bottom: 15px;
		}
	}
}

@media (min-width: 1200px){
	
}

/*Danh mục menu trái phải*/
.top-pro-new{
	.cate-menu{
		list-style: none;
		padding: 0px;
		margin: 0px;
		box-shadow: none;
		border: 1px solid #ddd;
		li{
			position: relative;
			list-style: none;
			i{
			    display: inline-block;
			    text-align: center;
			    height: 42px;
			    width: 42px;
			    line-height: 42px;
			    padding-top: 0px;
			    position: absolute;
			    top: 0px;
			    right: 0px;
			}
			a{
				display: block;
			    padding: 10px 15px;
			    padding-right: 5px;
			    text-transform: capitalize;
			}

			.cate-menu-sub1{
				padding: 0px;
				margin: 0px;
				list-style: none;
				background-color: #eee;
				border: 1px solid #ddd;
				height: auto;
				li{
					a{
						background-color: #fff;
						color: #333;
						border-bottom: 1px solid #ddd;
					}
					&:last-child a{
						border-bottom: 0px;
					}
					
				}
			}
		}
	}
}