.owl-carousel{
    .owl-nav{
        position: absolute;
        top: 50%;
        margin-top: -25px;
        left: 0;
        right: 0;
        .owl-prev, .owl-next {
            position: absolute;
            height: 50px;
            width: 50px;
            border-radius: 100%;
            color: inherit;
            border: none;
            z-index: 100;
            background-color: rgba(0,0,0,0.5) !important;
            display: none;
            opacity: 0;
            outline: none;
            i {
                font-size: 32px;
                color: #fff;
                line-height: 50px;
            }
        }
        .owl-prev {
            left: 15px;
        }
        .owl-next {
            right: 15px;
        }
    }
    &:hover .owl-prev{
        opacity: 1;
        display: block;
    }
    &:hover .owl-next{
        opacity: 1;
        display: block;
    }
}
