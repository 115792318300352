.header-top{
	background-color: #000;
	line-height: 40px;
	color: #ffeb3b;
	.slogan{
		i{
			color: #ffeb3b;
			margin-right: 5px;
		}
	}
	.hotline{
		height: 40px;
		background-color: $color-3st;
		color: #fff;
		position: relative;
		font-size: rem-calc(18);
		font-family: 'Roboto Condensed', sans-serif;
		font-weight: 700;
		&:before{
			border-right: 0 solid $color-3st;
		    border-left: 20px solid transparent;
		    border-bottom: 40px solid $color-3st;
		    content: "";
		    height: 0;
		    position: absolute;
		    left: -20px;
		    top: 0;
		    width: 0;
		}
		&:after{
		    content: "";
		    height: 40px;
		    width: 400px;
		    position: absolute;
		    right: -400px;
		    top: 0;
		    background-color: $color-3st;
		}
	}
}

@media (max-width: 767px){
	@media (min-width: 576px){

	}
}

@media (min-width: 768px){

}

@media (min-width: 992px){
	.header-fix{
		background-color: #fff;
	}
	.fixed-nav .header-fix{
		position: fixed;
		z-index: 99999;
		padding: 0 !important;
		left: 0;
		right: 0;
		top:0;
		background-color: #fff;
		box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
		-webkit-transition: all 0.5s ease;
		-moz-transition: all 0.5s ease;
		-o-transition: all 0.5s ease;
		transition: all 0.5s ease;
		#menu > ul > li > a{
			padding: 1rem 0 !important;
		}
	}

	// .effect{
	// 	&>li{
	// 		&>ul{
	// 			position: absolute;
	// 			z-index: 333;
	// 			top:100%;
	// 		    overflow: hidden;
	// 		    max-height: 0px;
	// 		    max-width: 0px;
	// 		    opacity: 0;
	// 		    padding:0;
	// 		    border-radius: 3px;
	// 		    background-color: #fff;
	// 		    box-shadow: 0px 4px 13px -3px #808080;
	// 		    list-style: none;
	// 		    transform-origin: 0% 0%;
	// 		    transform: translateY( 20px );
	// 	        transition: transform 0.5s ease, opacity 0.6s ease, max-height 0.6s step-end, max-width 0.6s step-end, padding 0.6s step-end;
	// 	        li{
	// 	        	a{
	// 	        		display: block;
	// 	        		padding: 10px 14px;
	// 	        		line-height: rem-calc(19);
	// 	        		border: 1px solid;
	// 	        		border-color: rgba(141, 141, 141, 0.07);
	// 	        		color: rgba(2, 11, 18, 0.63);
	// 	        		i{
	// 	        			margin-right: 10px;
	// 					    font-size: 18px;
	// 					    vertical-align: middle;
	// 	        		}
	// 	        	}
	// 	        	&:last-child{
	// 	        		a{
	// 	        			border:0px;
	// 	        		}
	// 	        	}
	// 	        	&:hover{
	// 					a{
	// 						background-color: #56aee3;
	// 						color:#fff !important;
	// 					}
	// 				}
	// 	        }
	// 		}
	// 		&:hover>ul{
	// 			overflow: visible;
	// 		    max-height: 3000px;
	// 		    max-width: 3000px;
	// 		    opacity: 1;
	// 		    padding:1rem;
	// 			transform: translateY( 0px );
	// 		    transition: transform 0.5s ease, opacity 0.2s ease, max-height 0s step-end, max-width 0s step-end, padding 0s step-end;
	// 		}
	// 	}
	// }

	// @media (max-width: 1199px) {
	// 	.header-top{
	// 		.center{
	// 			display: none;
	// 		}
	// 	}
	// }
}

@media (min-width: 1200px){
	
}