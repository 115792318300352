.br-crumb{
	background-color: transparent;
	.breadcrumb{
		background-color: transparent;
		padding: 0px;
		padding-top: 15px;
		margin: 0px;
		.breadcrumb-item + .breadcrumb-item:before{
			padding: 0 5px;
		    content: "/";
		}
		li{
			font-weight: $font-regular;
			font-size: rem-calc(14);
			color: $color-2st;
			a{
			    color: $color-2st;
			    transition: color 0.2s ease-in-out 0s;
			    text-decoration: none;
			    &:hover{
					color: $color-3st;
				}
			}
			@include headings{
				display: inline-block;
				padding: 0px;
				margin: 0px;
				font-size: 14px;
				font-weight: $font-regular;
			}
			h1{
				font-size: 14px;
			}
		}
	}
}