.quangcao-home{
	a{
		display: block;
		width: 100%;
		margin-bottom: 10px;
		overflow: hidden;
		img{
			width: 100%;
			max-width: 100%;
			display: block;

			-webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
		}
		&:last-child{
			margin-bottom: 0px;
		}
		&:hover{
			img{
				-webkit-transform: scale(1.1);
                transform: scale(1.1);
			}
		}
	}
}