.slider-categories{
	margin-top: 10px;
	.quangcao-home{
		width: 30%;
		width: calc(30% - 10px);
		margin-left: 10px;
		img{
			border-radius: 5px;
		}
	}
	.slider{
		width: 70%;
		img{
			border-radius: 5px;
		}
	}
}
@media(max-width: 767px){
	@media(max-width: 576px){
		.slider-categories{
			.quangcao-home{
				width: 100%;
				width: calc(100% - 0px);
				margin-left: 0px;
				margin-top: 10px;
			}
			.slider{
				width: 100%;
				width: calc(100% - 0px);
				margin: 0px;
			}
		}
	}
}

@media(min-width: 768px){
	@media(max-width: 991px){
		.slider-categories{
			margin-top: rem-calc(15);
			margin-bottom: rem-calc(15);
			.quangcao-home{
				display: none;
			}
			.slider{
				width: 100%;
				width: calc(100% - 0px);
				margin: 0px;
			}
		}
		.logo-mobile{
			display: none;
		}
	}
}
@media(min-width: 992px){
}
@media(min-width: 1200px){
}

// .async_load{
// 	display: block;
// 	.item{
// 		display: none;
// 		&:first-child{
// 			display: block;
// 		}
// 		img{
// 			width: 100%;
// 		}
// 	}
// }