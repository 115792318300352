body{
	max-width: 100%;
	overflow-x: hidden;
	font-size: rem-calc(16);
	background-color: #f0f0f0;
	color: #000;
	font-family: 'Roboto', sans-serif;
	font-weight: $font-regular;
}
// .wrap-page{
// 	width: 100%;
// 	background-color: #f00;
// 	padding: 15px;
// 	margin-top: 15px;
// }

.left-sidebar{
	width: calc(22%);
}
.right{
	width: 78%;
	width: calc(78% - 30px);
	margin-left: 30px;
}

img{
	max-width: 100%;
}

///////////////////////////////////////////
///////////////////////////////////////////
///////////////////////////////////////////
// mobile
@media (max-width: 767px){
	header {
	    border-bottom-color: white; //rgb(255, 255, 255)
	}
}

//table
@media (min-width: 768px){
	header {
	    border-bottom-color: red; //rgb(255, 0, 0)
	}
}

//computer
@media (min-width: 992px){
	header {
	    border-bottom-color: yellow; //rgb(255, 255, 0)
	}
}





@media(max-width: 767px){
	.left-sidebar{
		display: none;
	}
	.right{
		width: 100%;
		width: calc(100% - 0px);
		margin-left: 0px;
		padding-left: 0px;
		border-left: none;
	}
	body{
		background-color: #fff;
	}
	.wrap-page{
		padding: 0px;
		margin: 0px;
	}
	.container{
		padding-left:10px;
		padding-right: 10px;
	}
	.row{
		margin-left: -10px;
		margin-right: -10px;
	}
	.scroll{
		height: 120px;
		overflow-y: scroll;
	}
	body{
		font-size: rem-calc(17);
		line-height: rem-calc(26);
	}
	@media(min-width: 576px){

	}
}
@media(min-width: 1200px){
	.container{
		max-width: 1230px;
		width: 1230px;
	}
}