//danh mục sản phẩm
.product-category{
    padding-top: 10px;
    ul{
        display: inline-block;
        width: 100%;
        padding: 0px;
        margin: 0px;
        display: flex;
        li{
            display: inline-block;
            width: 25%;
            width: calc(25% - 7.5px);
            margin-right: 10px;
            a{
                img{
                    max-width: 100%;
                    -webkit-filter: sepia(100%);
                    filter: sepia(100%);
                    -webkit-filter: sepia(0);
                    filter: sepia(0);
                    -webkit-transition: .3s ease-in-out;
                    transition: .3s ease-in-out;
                }
                &:hover{
                    img{
                        -webkit-filter: sepia(0);
                        filter: sepia(0);
                    }
                }
            }
            &:nth-child(4n + 4){
                margin-right: 0px;
            }
        }
    }
}
.wrap-page{
    display: inline-block;
    width: 100%;
    background-color: #fff;
    padding: 15px;
    padding-top: 0px;
    // border-radius: 5px;
    margin-top: 10px;
}

.img_thumb{
    img{
        width: 100%;
        border-radius: 5px;
        margin-top: 10px;
        border: 2px solid $color-2st;
    }
}
//Sản phẩm
.pro-main--item {
    position: relative;
    overflow: hidden;
    background: #fff;
    z-index: 0;
    border: 1px solid rgba(0,0,0,.1);
    margin-bottom: -1px;
    margin-right: -1px;
    width: 50%;
    // width: calc()
    .tip__new {
        position: absolute;
        background-color: green;
        color: #fff;
        display: inline-block;
        bottom: 15px;
        right: 15px;
        font-size: 12px;
        border-radius: 3px;
        padding: 2px 5px;
        text-transform: capitalize;
    }
    .sale {
        position: absolute;
        background: #FFC107;
        color: #fff;
        display: inline-block;
        padding: 3px 7px;
        bottom: 15px;
        right: 5px;
        font-size: 14px;
        border-radius: 2px;
        text-transform: capitalize;
    }
    .pm-item__img{
        padding: 15px;
        position: relative;
        img{
            display: block;
            max-width: 100%;
            width: inherit;
            margin: 0 auto;
            -webkit-transform: scale(1);
            transform: scale(1);
            -webkit-transition: .3s ease-in-out;
            transition: .3s ease-in-out;
        }
        &:hover{
            img{
                -webkit-transform: scale(1.05);
                transform: scale(1.05);
            }
        }
    }
    .pm-item__cap{
        padding: 10px;
        padding-top: 0px;
        @include headings{
            margin: 0px;
            font-size: 14px;
            font-weight: $font-bold;
            // height: 35px;
            // overflow: hidden;
            // text-overflow: ellipsis;
            a{
                color: $color-1st;
                &:hover{
                    color: $color-2st;
                    text-decoration: none;
                }
            }
        }
        .info{
            list-style: none;
            padding: 5px 0px;
            margin: 0px;
            // border-top: 1px dotted rgba(0,0,0,.1);
            li{
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
            }
        }
        .item-cap__desc{
            .detail-pro__price{
                font-size: rem-calc(16);
                font-weight: $font-regular;
                color: $color-3st;
                small{
                    margin-left: 5px;
                    text-decoration: line-through;
                    font-size: 14px;
                    color: #888;
                    font-weight: 400;
                }
            }
        }
    }
    &:hover{
        border: 1px solid $color-2st;
        z-index: 1;
    }
    &:nth-child(2n + 2){
        margin-right: 0px;
    }
}

// @media(max-width: 767px){
//     .product-category{
//         padding-bottom: 0px;
//         ul{
//             flex-wrap: wrap;
//             li{
//                 width: 50%;
//                 width: calc(50% - 7.5px);
//                 margin-right: 15px;
//                 margin-bottom: 15px;
//                 &:nth-child(2n + 2){
//                     margin-right: 0px;
//                 }
//             }
//         }
//     }
//     // .pro-ly{
//     //     .pro-main--item{
//     //         width: 50%;
//     //         width: calc(50% - 0px);
//     //         margin-right: -1px;
//     //         margin-bottom: -1px;
//     //         &:nth-child(2n + 2){
//     //             margin-right: 0px;
//     //         }
//     //         .pm-item__img{
//     //             height: 150px;
//     //             padding: 15px;
//     //             display: flex;
//     //             justify-content: center;
//     //             align-items: center;
//     //         }
//     //         .pm-item__cap{
//     //         }
//     //     }
//     // }
//     @media(max-width: 576px){
        
//     }
// }

// @media(min-width: 768px){
//     @media(max-width: 991px){
//         .pro-ly{
//             .pro-main--item{
//                 width: 33.3%;
//                 width: calc(33.3% - 10px);
//                 margin-right: 15px;
//                 margin-bottom: 15px;
//                 &:nth-child(3n + 3){
//                     margin-right: 0px;
//                 }
//             }
//         }
//     }
// }
@media(min-width: 992px){
    .pro-ly-5{
        .pro-main--item{
            width: 20%;
            width: calc(20% + 0.8px);
            margin-right: -1px;
            &:nth-child(5n + 5){
                margin-right: 0px;
            }
            .pm-item__img{
                height: 240px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    max-height: 220px;
                }
            }
        }
    }

    .pro-ly-4{
        .pro-main--item{
            width: 25%;
            width: calc(25% + 0.75px);
            margin-right: -1px;
            &:nth-child(4n + 4){
                margin-right: 0px;
            }
            .pm-item__img{
                height: 240px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    max-height: 220px;
                }
            }
        }
    }
}