header{
	ul{
		list-style: none;
		padding: 0px;
		margin: 0px;
	}
}


@media (max-width: 991px){
	.header-main .register-advisory{
		display: none !important;
	}
	@media (max-width: 767px){
		header{
			// padding-top: 30px;
		}
		.header-top{
			display: none;
		}
		.header-bar{
			display: none;
		}
	}
}

@media (min-width: 768px){
}

@media (min-width: 992px){
	.button-res{
		display: none;
	}
}

@media (min-width: 1200px){
	
}