.bottom-footer{
	background-color: $color-2st;
	padding: 15px 0px;
	font-size: rem-calc(15);
	color: #fff;
	a{
		color: #fff;
	}
	h1{
		font-size: rem-calc(28);
	}
}
