/* Base Styles -------------------- */
a:hover{
	text-decoration: none;
}
@media (min-width:768px){.flex, .flex--wrap, .flex--between, .flex--center{display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex;}
.flex--wrap{flex-wrap:wrap;-webkit-flex-wrap:wrap;}
.flex--between{justify-content:space-between;-webkit-justify-content:space-between;}
.flex--center{-webkit-align-items:center;align-items:center;}
}
@media (max-width:767px){
	.flex-mobile{display:flex;display:-webkit-box;display:-ms-flexbox;display:-webkit-flex;display:flex;}
}