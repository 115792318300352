// .new-home{
// 	margin-top: 15px;
// }
//Tin ngang
.new-horizontal{
	display: flex;
	flex-wrap: wrap;
	padding-top: 15px;
	&--item{
		width: 100%;
		width: calc(100% - 0px);
		float: left;
		margin-bottom: 15px;
		padding-bottom: 15px;
		border-bottom: 1px dotted #ddd;
		&__img{
			width: 30%;
			display: inline-block;
			float: left;
			img{
				width: 100%;
				max-width: 100%;
				border-radius: 3px;
			}
		}
		&__content{
			float: left;
			width: 70%;
			width: calc(70% - 15px);
			margin-left: 15px;
			display: inline-block;
			text-align: justify;
			padding-right: 15px;
			@include headings{
				padding: 0px;
				margin: 0px;
				font-size: rem-calc(18);
				display: inline-block;
				padding-bottom: 5px;
				font-weight: $font-bold;
				a{
					color: $color-1st;
					&:hover{
						color: $color-2st;
					}
				}
			}
			.desc{
				color: #666;
				font-size: rem-calc(15);
			}
		}
	}
}


//Tin dọc
.new-vertical{
	display: flex;
	flex-wrap: wrap;
	&--item{
		width: 33.333%;
		width: calc(33.333% - 10px);
		display: inline-block;
		margin-right: 15px;
		margin-bottom: 15px;
		background-color: #f4f4f4;
		&__img{
			width: 100%;
			display: inline-block;
			position: relative;
			overflow: hidden;
			a{
				display: block;
				img{
					width: 100%;
					max-width: 100%;
				}
				span{
					position: absolute;
					content: "";
					top: 50%;
					z-index: 9999;
					background-color: #f00;
					left: 0px;
					opacity: 0;
					visibility: hidden;
				}
			}
		}
		&__content{
			width: 100%;
			width: calc(100% - 0px);
			display: inline-block;
			padding: 0px 10px;
			@include headings{
				margin: 0px;
				font-size: rem-calc(16);
				font-weight: $font-regular;
				overflow: hidden;
				text-overflow: ellipsis;
				display: inline-block;
				width: 100%;
				a{
					color: $color-1st;
					&:hover{
						color: $color-2st;
					}
				}
			}
			.desc{
			}
		}
		&:nth-child(3n + 3){
			margin-right: 0px;
		}
		
	}
}

@media (max-width: 767px){
	.new-vertical{
		&--item{
			width: 100%;
			width: calc(100% - 0px);
			margin-right: 0px;
			background-color: transparent;
			&__img{
				width: 30%;
			}
			&__content{
				width: 70%;
				width: calc(70% - 15px);
				padding-top: 0px;
			}
		}
	}

	.new-horizontal{
		&--item{
			width: 100%;
			&__img{
				width: 100%;
			}
			&__content{
				width: 100%;
				padding-top: 10px;
				margin-left: 0px;
			}
		}
	}
	@media (min-width: 576px){

	}
}

@media(min-width: 992px){
	.new-vertical{
		&--item{
			&__img{
				img{
					// height: 170px;
				}
			}
		}
	}
}


// //tin nhỏ ngang
// .newsmall-horizontal{
// 	padding: 15px 0px;
// 	&--item{
// 		display: flex;
// 		flex-wrap: wrap;
// 		margin-bottom: 5px;
// 		margin-bottom: 10px;
// 		border-bottom: 1px dotted #ddd;
// 		padding-bottom: 10px;
// 		&__img{
// 			width: 80px;
// 			display: inline-block;
// 			img{
// 				width: 100%;
// 				max-width: 100%;
// 			}
// 		}
// 		&__content{
// 			width: 100%;
// 			width: calc(100% - 95px);
// 			margin-left: 15px;
// 			display: inline-block;
// 			@include headings{
// 				padding: 0px;
// 				margin: 0px;
// 				font-size: rem-calc(15);
// 				font-weight: $font-regular;
// 				a{
// 					color: $color-1st;
// 					&:hover{
// 						color: $color-2st;
// 					}
// 				}
// 			}
// 			.item-cap__desc{
// 				display: inline-block;
// 				width: 100%;
// 				color: #f00;
// 				font-weight: 700;
// 				font-size: rem-calc(14);
// 				small{
// 					text-decoration: line-through;
// 					color: #888;
// 					display: inline-block;
// 					width: 100%;
// 				}
// 			}
// 		}
// 	}
// }

.list-new{
	padding: 0px;
	margin: 0px;
	list-style: none;
	li{
		display: inline-block;
		width: 100%;
		@include headings{
			margin: 0px;
			padding: 0px;
			a{
				font-size: rem-calc(16);
				color: $color-2st;
				position: relative;
				padding-left: 25px;
				&:hover{
					text-decoration: underline;
				}
				&:before{
					content: "";
					position: absolute;
					left: 0px;
					top: 5px;
					width: 5px;
					height: 5px;
					border-radius: 100%;
					background-color: #666;
				}
			}
		}
	}
}