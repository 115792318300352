.exchange-quotation{
	padding-top: rem-calc(50);
}
.visible-mobi-tab{
	display: none;
}
.exquot-table{
	font-size: rem-calc(14);
  	line-height: rem-calc(26);
  	color: #475155;
  	position: relative;
  	z-index: 0;
  	// width: 920px;
  	margin: 0 auto;
  	text-align: left;
  	table {
  		td:first-child {
		    padding-left: 0;
		    width: 220px;
		    small{
		    	margin-top: rem-calc(8);
		    	font-size: rem-calc(13);
			    line-height: rem-calc(18);
			    display: block;
		    }
		}
		td {
		  	padding: 22px 10px 17px;
		  	vertical-align: top;
		}
		tr:first-child{
			td{
				// width: 175px;
				text-align: center;
				font-size: rem-calc(23);
			  	line-height: rem-calc(30);
			  	color: #000000;
			  	font-weight: normal;
			  	span{
			  		font-size: rem-calc(19);
				    line-height: rem-calc(30);
				    color: #2fc7f7;
				    display: block;
			  	}

			}
		}
		tr + tr > td{
			& + td{
				text-align: center;
				font-size: rem-calc(19);
    			line-height: rem-calc(28);
    			font-weight: lighter;
    			i{
    				color: #2fc6f7;
    				font-size: rem-calc(26);
    			}
    			b{
    				width: rem-calc(25);
				    border-radius: 100%;
				    border: 1px solid;
				    height: rem-calc(25);
				    line-height: rem-calc(22);
				    display: inline-block;
				    background: #2fc6f7;
				    color: #fff;
				    font-weight: bold;
				    font-size: rem-calc(20);
				    &.minus{
				    	background: #ced5d8;
				    }
    			}
			}
		}
	}
	.i-border {
	    border-radius: 6px;
	    position: absolute;
	    z-index: -2;
	    right: -2px;
	    top: -2px;
	    bottom: -2px;
	    border: 2px solid #e5ecef;
	}
	.i-hover {
	    border-radius: 6px;
	    -o-transition: right 0.3s;
	    -moz-transition: right 0.3s;
	    -webkit-transition: right 0.3s;
	    transition: right 0.3s;
	    position: absolute;
	    z-index: 3;
	    right: -2px;
	    top: -2px;
	    bottom: -2px;
	    border: 2px solid #2fc7f7;
	}
	&[data-hover="0"] .i-hover,
	&[data-hover="-1"] .i-hover {
	  display: none;
	}
}

.x-button {
	-o-transition-property: border-color, color, opacity;
    -moz-transition-property: border-color, color, opacity;
    -webkit-transition-property: border-color, color, opacity;
    transition-property: border-color, color, opacity;
    -o-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    display: inline-block;
    position: relative;
    z-index: 333;
    vertical-align: middle;
    text-decoration: none;
    text-align: center;
    font-weight: 400;
    text-decoration: none !important;
    &:hover{
    	opacity: .5;
    	i{
    		transform: translate(34px);
    	}
    }
}
.x-button.m-blue {
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    border-radius: 20px;
    background: #2fc7f7;
    min-width: 60px;
    padding: 11px 23px;
    text-transform: uppercase;
}
.x-button.m-green {
    font-size: 13px;
    line-height: 18px;
    color: #ffffff;
    border-radius: 20px;
    min-width: 60px;
    padding: 11px 23px;
    text-transform: uppercase;
	background: #bfe819; /* Old browsers */
	background: -moz-linear-gradient(top, #bfe819 0%, #93c900 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #bfe819 0%,#93c900 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #bfe819 0%,#93c900 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bfe819', endColorstr='#93c900',GradientType=0 ); /* IE6-9 */
    text-shadow: 0 1px 1px rgba(45, 77, 0, 0.26);
}
.x-button.m-blue-border{
	position: relative;
	font-size: rem-calc(14);
    line-height: 18px;
    color: #2fc7f7;
    border-radius: 20px;
    border: 2px solid #2fc7f7;
    min-width: 60px;
    padding: 10px 35px;
    text-transform: uppercase;
    i{
    	position: absolute;
	    right: 15px;
	    top: 8px;
	    font-size: rem-calc(20);
    }
}

@media (max-width: 991px){
	.visible-destop{
	    display: none;
	}
	.visible-mobi-tab{
		display: block;
	}
	.exquot-table{
	  	table {
	  		width: 100%;
			tr{
				&>td:first-child{
					display:none;
				}
			}
			tr:first-child{
				td{
					font-size: rem-calc(16);
				  	span{
				  		font-size: rem-calc(14);
				  	}

				}
			}
			tr:last-child{
				display: none;
			}
			td {
			  	padding: 15px 6px;
			}
			tr + tr > td{
				& + td{
					font-size: rem-calc(16);
				}
			}
		}
		.cus_heading{
			display: table-cell !important;
		    background: #fff;
		    width: 100%;
		    text-align: center;
		    padding: rem-calc(7) 0px;
		    font-size: rem-calc(16);
		    border-top: 1px solid #e5ecef;
		    small:not(.visible-mobi-tab){
		    	display: none !important;
		    }
		}
		.i-border,
		.x-button,
		.i-hove{
		    display: none;
		}
	}
}

@media (min-width: 768px){

}

@media (min-width: 992px){
	.exquot-table{
	  	table {
			tr>td:first-child{
				font-size: rem-calc(16);
		  		line-height: rem-calc(20);
			    font-weight: bold;
			    display: block;
			    text-align: left !important;
			}
			td {
			  	&:last-child {
				  background: #f8fafc;
				}
			}
			tr + tr > td{
				border-top: 1px solid #e5ecef;
			}
		}
		&.table-responsive{
			overflow-x: initial;
		}
	}
}

@media (min-width: 1200px){
	
}

