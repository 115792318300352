//Custom variables

// Descriptive color variables

// Functional color variables
$color-1st : #000;

//blue light
$color-2st : #546ce8;

//red
$color-3st : #ce0707;

//blue dark
$color-4st : #0d2187;
// Font stacks

// $font-light: 300;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
// $font-supperbold: 900;

// Asset paths
$path-image   :   '../img';
$path-fonts     :  '../fonts';
