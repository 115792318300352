.camket{
	padding: 0px;
	margin:0px;
	list-style: none;
	li{
		width: 100%;
		display: flex;
		align-items: center;
		font-size: rem-calc(15);
		padding: 5px 0px;
		img{
			margin-right: 10px;
		}
		&:last-child{
			border-bottom: none;
		}
		a{
			color: $color-2st;
			border-top: 1px solid rgba(0,0,0,.1);
			padding-top: 10px;
		}
	}
}